import * as React from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import {
  SquareImageButton,
  SquareImageContent,
  SquareImageDescription,
  SquareImageTextWrapper,
  SquareImageTitle,
  SquareImageWrapper
} from "components/images/ImageSquare/ImageSquare.styled";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import { useImageDatabaseContext } from "contexts/ImageContext";
import Translate from "utils/translation/translation";

interface SquareImageProps {
  title: string;
  description: string;
  imageName: string;
  dropText?: boolean;
  left?: boolean;
  isButton?: boolean;
  buttonText?: string;
  reverseTitle?: boolean;
}

export const SquareImage = ({
  title,
  description,
  imageName,
  left = false,
  dropText = false,
  isButton = false,
  buttonText,
  reverseTitle = false
}: SquareImageProps) => {
  const [imageDatabase] = useImageDatabaseContext();
  const image = imageDatabase[imageName].childImageSharp.gatsbyImageData;

  return (
    <SquareImageWrapper left={left} dropText={dropText} isButton={isButton}>
      <LocomotiveElement scrollSpeed={0.5} variant="SquareImageParent">
        <SquareImageContent image={image} draggable={false} alt="Square image" />
      </LocomotiveElement>
      <SquareImageTextWrapper>
        <SquareImageTitle reverseTitle={reverseTitle} left={left}>
          {Translate(title)}
        </SquareImageTitle>
        <SquareImageDescription>{Translate(description)}</SquareImageDescription>
      </SquareImageTextWrapper>
      {isButton && (
        <SquareImageButton>
          <TrackedButton category="form" action={title + "-form-open"}>
            <CtaButton text={buttonText} />
          </TrackedButton>
        </SquareImageButton>
      )}
    </SquareImageWrapper>
  );
};

export default SquareImage;
