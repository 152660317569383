import { IconContainerTitleWrapper } from "components/common/other/IconContainer/IconContainer.styled";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  AdvantageDescriptionFont,
  AdvantageTitleFont,
  IconContainerIdFont,
  NecessityDescriptionFont
} from "styled/common/font-styles/CommonFonts";
import { NonSelectable } from "styled/common/Utils";
import styled, { css } from "styled-components";

const NECESSITY_SECTION_DESKTOP_ROW = 4;

export const NecessityWrapper = styled.section`
  ${CalculateFromHeight("margin-top", { mobile: 30, desktop: 10 })}
  ${CalculateFromHeight("height", { mobile: 105, tablet: 70, desktop: 20 })}

  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${IconContainerTitleWrapper} {
    ${CalculateFromWidth("margin-left", { tablet: -3 })}
  }
`;

export const NecessityDescription = styled.span`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 9;
    grid-row: 6;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 7;
    grid-row: 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 8;
    grid-row: 4 / span 3;
  }
  ${NecessityDescriptionFont}
  ${CalculateFromHeight("margin-top", { desktop: 3 })}
`;

const NecessitySectionFirst = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW} / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 4} / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 4} / span 3;
  }
`;

const NecessitySectionSecond = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 2} / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 8} / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 8} / span 3;
  }
  ${CalculateFromHeight("margin-top", { mobile: 5 })}
`;

const NecessitySectionThird = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 4} / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 10} / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: ${NECESSITY_SECTION_DESKTOP_ROW + 12} / span 3;
  }
  ${CalculateFromHeight("margin-top", { mobile: 5 })}
`;

export const NecessitySectionWrapper = styled.div<{ number: number }>`
  display: flex;
  flex-direction: column;

  ${({ number }) =>
    number === 1 || number === 3
      ? css`
          grid-column: 2 / span 5;

          ${({ theme: { breakpoints } }) => breakpoints.tablet} {
            grid-column: 3 / span 5;
          }
        `
      : css`
          grid-column: 7 / span 5;

          ${({ theme: { breakpoints } }) => breakpoints.tablet} {
            grid-column: 8 / span 5;
          }
        `}

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 3 / span 9;
  }

  ${({ number }) => number === 1 && NecessitySectionFirst}
  ${({ number }) => number === 2 && NecessitySectionSecond}
  ${({ number }) => number === 3 && NecessitySectionThird}

  ${CalculateFromHeight("margin-bottom", { desktop: 4 })}
  ${CalculateFromWidth("margin-left", { desktop: 7 })}
`;

export const NecessitySectionTitle = styled.h4`
  ${AdvantageTitleFont}
  ${CalculateFromWidth("margin-left", { mobile: 1, tablet: 1, desktop: 1 })}
`;

export const NecessitySectionDescription = styled.p`
  max-width: 420px;
  width: 100%;
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    width: 80%;
  }
  ${AdvantageDescriptionFont}
  ${CalculateFromWidth("margin-left", { tablet: 0, desktop: 1.7 })}
`;

export const NecessitySectionId = styled.p<{ color?: string }>`
  ${NonSelectable}
  ${IconContainerIdFont}
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    position: absolute;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: absolute;
  }
  ${CalculateFromWidth("margin-left", { mobile: -9, tablet: -7, desktop: -1 })}
  ${CalculateFromWidth("margin-right", { mobile: 2, tablet: 0.75, desktop: 0.75 })}
  ${CalculateFromWidth("margin-top", { mobile: 2, tablet: 0.5 })}
  ${CalculateFromWidth("width", { mobile: 2, tablet: 2, desktop: 2 })};
  color: ${({ color }) => color && color};
`;

export const NecessitySectionTitleWrapper = styled.div`
  ${CalculateFromWidth("margin-left", { mobile: -1, tablet: -1, desktop: -0.5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 2, tablet: 2, desktop: 2 })}
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 80%;
  }
`;
