import React, { Fragment, useRef } from "react";

import Advantages from "components/common/Advantages/Advantages";
import { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import Necessity from "components/common/necessity/Necessity";
import PageTitle, { PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import About from "components/common/sections/About/About";
import CallToAction from "components/common/sections/CallToAction/CallToAction";
import { Service } from "components/common/sections/Services/Service";
import Services from "components/common/sections/Services/Services";
import WhyUs from "components/common/sections/WhyUs/WhyUs";
import { SquareImage } from "components/images/ImageSquare/ImageSquare";
import Integrations from "components/Integrations/Integrations";
import Layout from "components/layout/Layout/Layout";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import FacebookIcon from "media/images/icons/other/facebook-circle-icon.png";
import GmailIcon from "media/images/icons/other/gmail-icon.png";
import GoogleWorkspaceIcon from "media/images/icons/other/google-workspace-icon.png";
import HubspotIcon from "media/images/icons/other/hubspot-icon.png";
import SlackIcon from "media/images/icons/other/slack-icon.png";
import { GetSeoData } from "utils/seo-utils";
import Translate from "utils/translation/translation";

const Automation = ({ location }: PageProps) => {
  const rightCloudWrapperRef = useRef<HTMLDivElement>(null);
  const leftCloudWrapper = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <GatsbySeo {...GetSeoData("Automation")} />
      <Layout location={location}>
        <MainCloud color="VioletBlack" variant="MainCloudWrapper" scrollSpeed={-7.5} />
        <PageTitle {...pageTitleData} />
        <About />
        <SquareImage
          title="Automation_SquareImage1_Header"
          description="Automation_SquareImage1_Description"
          imageName="AutomationSquareOne"
          left
        />
        <Necessity>
          <LocomotiveElement variant="AutomationNecessityCloud" scrollSpeed={-0.5}>
            <RendererWrapper
              elementRef={leftCloudWrapper}
              variant="fill-background-space"
              type="cloud"
              additionalData={"VioletBlack"}
            />
          </LocomotiveElement>
        </Necessity>
        <Services
          headerData={advantagesServicesHeaderData}
          withoutMargin
          {...advantagesServices}
          shouldHideHeader
        />
        <Integrations description="Integrations_Description_Automation" icons={integrationIcons}>
          <LocomotiveElement variant="AutomationIntegrationsCloud" scrollSpeed={-0.5}>
            <RendererWrapper
              elementRef={rightCloudWrapperRef}
              variant="fill-background-space"
              type="cloud"
              additionalData={"PinkViolet"}
            />
          </LocomotiveElement>
        </Integrations>
        <CallToAction
          headerData={callToActionHeader}
          description="Automation_Call_To_Action_Description"
          buttonText="Automation_Call_To_Action_Button_Text"
        />
        <WhyUs />
        <Advantages />
        <SquareImage
          title="Automation_SquareImage2_Header"
          description="Automation_SquareImage2_Description"
          imageName="AutomationSquareTwo"
          buttonText={"Automation_SquareImage2_ButtonText"}
          left
          isButton
        />
        <HomeProjects />
      </Layout>
    </Fragment>
  );
};

export default Automation;

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "automation-title",
  isTitle: true,
  headers: [
    {
      text: "Automation_Header_Auto",
      variant: "AutomationHeaderAuto",
      id: "automation-auto",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      scrollSpeed: 1
    },
    {
      text: "Automation_Header_Mation",
      variant: "AutomationHeaderMation",
      id: "auto-mation",
      animation: { x: 100, opacity: 0, duration: 1, delay: 0.5 },
      scrollSpeed: 0.1,
      horizontalScrollSpeed: -0.5
    }
  ],
  subtitle: {
    text: "Automation_Header_Subtitle",
    variant: "automation-subtitle"
  }
};

const advantagesServices: Record<string, Service> = {
  firstService: {
    title: Translate("Automation_Advantage_Header_One"),
    description: Translate("Automation_Advantage_Description_One"),
    iconName: "ExperienceIcon"
  },
  secondService: {
    title: Translate("Automation_Advantage_Header_Two"),
    description: Translate("Automation_Advantage_Description_Two"),
    iconName: "ArIcon"
  },
  thirdService: {
    title: Translate("Automation_Advantage_Header_Three"),
    description: Translate("Automation_Advantage_Description_Three"),
    iconName: "EfficiencyIcon"
  }
};

const callToActionHeader: HeaderGroupProps = {
  headers: [
    {
      text: "Automation_Call_To_Action_Header",
      variant: "CallToActionHeader",
      fontVariant: "call-to-action-header",
      id: "call-to-action-header",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 }
    }
  ]
};

const advantagesServicesHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Common_Header_Automation_Advantages_Services",
      variant: "AutomationAdvantagesServicesHeader",
      id: "automation-advantages-services-header",
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.75
    }
  ]
};

const integrationIcons = [
  { image: GoogleWorkspaceIcon, alt: "Google workspace icon" },
  { image: GmailIcon, alt: "Gmail icon" },
  { image: SlackIcon, alt: "Slack icon" },
  { image: HubspotIcon, alt: "Hubspot icon" },
  { image: FacebookIcon, alt: "Facebook icon" }
];
