import React from "react";

import HeaderGroup, { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import {
  NecessityDescription,
  NecessityWrapper
} from "components/common/necessity/Necessity.styled";
import { Circ } from "gsap";
import Translate from "utils/translation/translation";

type NecessitySection = {
  title: string;
  description: string;
  color?: string;
};

export type NecessityProps = {
  headerData?: HeaderGroupProps;
  description?: string;
  firstSection?: NecessitySection;
  secondSection?: NecessitySection;
  thirdSection?: NecessitySection;
};

export type NecessitySectionNumber = 1 | 2 | 3;

export const Necessity: Component<NecessityProps> = ({
  children,
  headerData = defaultHeaderData,
  description = defaultDescription
}) => {
  return (
    <NecessityWrapper>
      <HeaderGroup {...headerData} />
      <NecessityDescription>{description}</NecessityDescription>
      {children}
    </NecessityWrapper>
  );
};

export default Necessity;

const defaultHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Common_Header_Automation_Necessity_Desktop",
      variant: "NecessityTitleDesktop",
      id: "necessity-title-desktop",
      animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.5
    },
    {
      text: "Common_Header_Automation_Necessity_Mobile_One",
      variant: "NecessityTitleMobileOne",
      id: "necessity-title-mobile-one",
      animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.5
    },
    {
      text: "Common_Header_Automation_Necessity_Mobile_Two",
      variant: "NecessityTitleMobileTwo",
      id: "necessity-title-mobile-two",
      animation: { x: "20%", opacity: 0, duration: 0.75, delay: 0.125, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    }
  ]
};

const defaultDescription = Translate("Automation_Necessity_Description");
