import { Wrapper } from "components/locomotive/LocomotiveElement/LocomotiveElement.styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { Header4, StandardFont } from "styled/common/font-styles/CommonFonts";
import { Background, NonSelectable } from "styled/common/Utils";
import styled, { css } from "styled-components";

type SquareImageProps = {
  left?: boolean;
  dropText?: boolean;
  isButton?: boolean;
};

type SquareImageTitleProps = {
  reverseTitle?: boolean;
  left?: boolean;
};

export const SquareImageContent = styled(GatsbyImage)`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin: auto auto auto 0;
  }
  ${CalculateFromWidth("height", { mobile: 80, tablet: 80, desktop: 50 })}
  ${CalculateFromWidth("width", { mobile: 90, tablet: 90, desktop: 55 })}
  ${NonSelectable};
  & > img {
    ${Background};
  }
`;

export const SquareImageTitle = styled.h4<SquareImageTitleProps>`
  direction: ${({ reverseTitle }) => (reverseTitle ? "rtl" : "ltr")};
  ${Header4}
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    position: relative;
    z-index: 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: relative;
    z-index: 1;
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 10, tablet: 8 })}
  ${CalculateFromHeight("margin-top", { mobile: 10, tablet: 18 })}
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 15 })}
  ${({ left }) =>
    !left &&
    css`
      ${CalculateFromWidth("max-width", { desktop: 35 })}
    `}
`;

export const SquareImageDescription = styled.p`
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    margin-top: 0;
    margin-bottom: auto;
    z-index: 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-top: 0;
    margin-bottom: auto;
    z-index: 1;
  }
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 35 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 5 })}
  ${StandardFont};
`;

export const SquareImageTextWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 12;
    grid-row: 5 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 12;
    grid-row: 6 / span 3;
  }
  ${CalculateFromWidth("margin-left", { mobile: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: 5 })}
`;

const SquareImageBase = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(8, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(9, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(10, 1fr);
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 10 })}
  ${CalculateFromHeight("height", { mobile: 190, tablet: 130, desktop: 100 })}
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
`;

const SquareImageRight = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${SquareImageTextWrapper} {
      grid-column: 1 / span 6;
      grid-row: 3 / span 6;
      ${CalculateFromWidth("margin-left", { desktop: 3 })}
      z-index: 1;
    }
    ${SquareImageTitle} {
      ${CalculateFromHeight("margin-bottom", { desktop: 5 })}
      ${CalculateFromHeight("margin-top", { desktop: 2 })}
    position: relative;
      z-index: 1;
    }
    ${SquareImageDescription} {
      ${CalculateFromWidth("margin-right", { desktop: 20 })}
      margin-top: 0;
      margin-bottom: auto;
      z-index: 1;
    }
    ${Wrapper} {
      grid-column: 5 / span 8;
      grid-row: 2 / span 6;
      margin-left: auto;
    }
  }
`;

const SquareImageLeft = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${SquareImageTextWrapper} {
      grid-column: 7 / span 6;
      grid-row: 2 / span 8;
      z-index: 1;
      ${CalculateFromWidth("margin-left", { desktop: 1 })}
      ${CalculateFromWidth("margin-right", { desktop: 3 })}
    }

    ${SquareImageTitle} {
      ${CalculateFromHeight("margin-bottom", { desktop: 7 })}
      ${CalculateFromHeight("margin-top", { desktop: 5 })}
      position: relative;
      z-index: 1;
    }
    ${SquareImageDescription} {
      margin-top: 0;
      margin-bottom: auto;
      z-index: 1;
      ${CalculateFromWidth("margin-left", { desktop: 18 })}
      ${CalculateFromWidth("margin-right", { desktop: 1 })}
    }
    ${Wrapper} {
      grid-column: 1 / span 7;
      grid-row: 2 / span 6;
      ${CalculateFromWidth("margin-left", { desktop: 3 })}
      margin-right: auto;
    }
  }
`;

const SquareImageDropText = css`
  ${SquareImageTitle} {
    ${CalculateFromHeight("margin-top", { tablet: 27 })}
  }
`;

export const SquareImageWrapper = styled.section<SquareImageProps>`
  ${SquareImageBase}
  ${({ left }) => (left ? SquareImageLeft : SquareImageRight)}
  ${({ dropText }) => dropText && SquareImageDropText}
  ${({ isButton }) => isButton && CalculateFromHeight("height", { mobile: 250 })}
`;

export const SquareImageButton = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 11 / span 3;
    grid-row: 7 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 3 / span 6;
    grid-row: 10 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 8;
    grid-row: 11 / span 8;
    max-width: 210px;
    max-height: 210px;
  }
  max-width: 240px;
  max-height: 240px;
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: 18, desktop: 4 })}
`;
